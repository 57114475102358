<template>
  <v-expansion-panels flat accordion v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header v-slot="{ open }" class="pt-1 pb-1 px-2 mb-1">
        <v-row no-gutters>
          <v-col cols="12" class="text--secondary">
            <v-slide-x-transition leave-absolute>
              <span v-if="open" class="d-flex justify-center">検索条件を入力して下さい</span>
              <v-row v-else no-gutters justify="start">
                <v-col v-for="(input, key, index) in inputs" :key="index" cols="12" sm="3" class="pt-2">
                  <v-row v-if="input.isCheckbox" align="center">
                    <v-checkbox
                      class="pt-1 pl-4 text--secondary"
                      :label="input.label"
                      v-model="reactiveVar[key]"
                      dense
                      disabled
                    ></v-checkbox>
                  </v-row>
                  <span v-else-if="input.isSelectBox">
                    <v-icon v-if="input.icon" class="pr-1">{{ input.icon }}</v-icon
                    >{{ input.label }} : {{ selection[key][reactiveVar[key]] }}</span
                  >
                  <span v-else-if="input.isCalendar">
                    <v-icon class="px-1">mdi-calendar-question</v-icon>
                    {{ input.label }} :
                    <span>{{ dateText(reactiveVar[key]) }}</span>
                  </span>
                  <span v-else class="px-1"
                    ><v-icon v-if="input.icon" class="pr-1">{{ input.icon }}</v-icon
                    >{{ input.label }} : {{ reactiveVar[key] }}</span
                  >
                </v-col>
              </v-row>
            </v-slide-x-transition>
          </v-col>
        </v-row>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <slot></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { courier } from '@/consts.js';
export default {
  data() {
    return {
      panel: 1,
    };
  },
  props: {
    reactiveVar: {
      type: Object || Array,
      required: true,
    },
    inputs: {
      type: Object || Array,
      required: true,
    },
  },
  computed: {
    selection() {
      const storeModule = this.$route.meta.storeModule ? this.$route.meta.storeModule : null;
      const progress = {};
      const courierSelection = {};
      storeModule
        ? this.$store.state.defined.progressStatus[storeModule].forEach(({ text, value }) => {
            progress[value] = text;
          })
        : null;
      courier.forEach(({ text, value }) => {
        courierSelection[value] = text;
      });
      return {
        status: this.$store.getters['defined/statusItems'].map(({ text }) => text),
        progress,
        courier: courierSelection,
      };
    },
  },
  methods: {
    dateText(dates) {
      if (!dates || dates.length == 0) return null;
      const formattedDateRange = Array();
      if (typeof dates === 'string') {
        return dates;
      } else {
        dates.forEach((date) => {
          if (date) {
            const [year, month, day] = date.split('-');
            formattedDateRange.push(`${year}/${month}/${day}`);
          } else {
            formattedDateRange.push(null);
          }
        });
        return formattedDateRange.join(' 〜 ');
      }
    },
  },
};
</script>
<style scoped>
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 32px !important;
}
</style>
